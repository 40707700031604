import { usePathname, useRouter } from 'next/navigation';
import { useCallback, useMemo } from 'react';
import { useLogout } from '~/app/auth/_hooks/use-logout/use-logout';
import { useTellerSession } from '~/common/hooks/use-auth/use-teller-session';
import { useClientOnlyMediaQuery } from '~/common/hooks/use-client-only-media-query';
import { useFirebaseAnalytics } from '~/common/hooks/use-firebase-analytics';
import { NOVEL_STUDIO_URL } from '~/utils/constants';
import { routes } from '~/utils/routes';
import { Media } from '~/utils/styles/enums';
import { useDisclosure } from '@app/_hooks/use-disclosure';
import { useAfterAuthRedirect } from '@app/auth/_hooks/use-after-auth-redirect';
import { useGetNotificationCount } from './use-get-notification-count';
import { useQuickReturnHeader } from './use-quick-return-header';

export const useLocalState = () => {
  const router = useRouter();
  const { removeRedirectionPath } = useAfterAuthRedirect();
  const { removeRedirectionPath: removeRegisterRedirection } =
    useAfterAuthRedirect();
  const { logEvent } = useFirebaseAnalytics();
  const { session, status: sessionStatus } = useTellerSession();
  const isAnonymous = session?.isAnonymous;
  const isSessionReady = sessionStatus === 'authenticated';

  // logged in
  const user = useMemo(() => {
    if (!session?.tellerUser || sessionStatus !== 'authenticated') return;

    return {
      id: session.tellerUser.id,
      name: session.tellerUser.name,
      thumbnailUrl: session.tellerUser.thumbnail,
      isAnonymous: isAnonymous ?? false,
      isVip: session.tellerUser.isVipUser ?? false,
      isOfficialWriter: session.tellerUser.isOfficialWriter ?? false,
      followeeCount: session.tellerUser.followeesCount ?? 0,
      followerCount: session.tellerUser.followersCount ?? 0,
      searchableStoryCount: session.tellerUser.searchableStoryCount ?? 0,
    };
  }, [isAnonymous, session?.tellerUser, sessionStatus]);

  // handle on click write story
  const handleStoryWriteClick = useCallback(async () => {
    if (isAnonymous === true) {
      router.push(routes.login.url(), undefined);
    } else {
      if (typeof window !== 'undefined') {
        logEvent('header_click_write_story');
        window.location.href = `${NOVEL_STUDIO_URL}?webUid=${session?.uid}`;
      }
    }
  }, [isAnonymous, router, logEvent, session?.uid]);

  const handleOnClickMyStories = useCallback(async () => {
    if (isAnonymous === true) {
      router.push(routes.login.url(), undefined);
    } else {
      if (typeof window !== 'undefined') {
        logEvent('drawer_click_my_story');
        window.location.href = `${NOVEL_STUDIO_URL}?webUid=${session?.uid}`;
      }
    }
  }, [isAnonymous, logEvent, router, session?.uid]);

  const { notificationCount } = useGetNotificationCount();

  // Drawer
  const drawerDisclosure = useDisclosure();

  // Header menu
  // PC will be a popover, SP a dialog
  const headerMenuDisclosure = useDisclosure();

  // Search
  const searchDisclosure = useDisclosure();

  // Appbar Handling
  const { appBarContainerRef } = useQuickReturnHeader();

  const screenLarge = useClientOnlyMediaQuery(Media.LARGE);
  const isMobile =
    typeof screenLarge === 'undefined' ? undefined : !screenLarge;

  // login
  const currentPath = usePathname();
  const handleOnClickLogin = useCallback(() => {
    if (typeof window === 'undefined') return;
    if (currentPath === '/') {
      try {
        removeRedirectionPath();
        removeRegisterRedirection();
      } catch {}
    }

    router.push(routes.login.url());
  }, [currentPath, removeRedirectionPath, removeRegisterRedirection, router]);

  // logout
  const { logout } = useLogout({
    onComplete: () => drawerDisclosure.onClose(),
  });

  const handleOnClickLogout = useCallback(() => {
    headerMenuDisclosure.onClose();
    try {
      removeRedirectionPath();
      removeRegisterRedirection();
    } catch {}
    logout();
  }, [
    headerMenuDisclosure,
    logout,
    removeRedirectionPath,
    removeRegisterRedirection,
  ]);

  const handleOnClickRegister = useCallback(() => {
    router.push(routes.register.url());
  }, [router]);

  return {
    isMobile,
    isSessionReady,
    isAnonymous,
    user,
    notificationCount,
    sessionStatus,
    handleStoryWriteClick,
    handleOnClickMyStories,
    handleOnClickLogin,
    handleOnClickLogout,
    handleOnClickRegister,
    headerMenuDisclosure,
    searchDisclosure,
    appBarContainerRef,
  };
};
